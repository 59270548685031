var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto pa-4",attrs:{"width":"800","height":"75vh","elevation":"2","outlined":""}},[_c('v-card-title',[_vm._v(" Subject Student Assignment "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"mt-10",attrs:{"headers":_vm.subjectHeaders,"items":_vm.subjects,"search":_vm.search,"fixed-header":"","height":"45vh","loading":_vm.loadingSubjects},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.addItem(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":"","medium":""}},[_vm._v(" mdi-account-multiple-plus ")]),_vm._v(" Add Students ")],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"700px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.editedSubject.title)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchStudentAssigned),callback:function ($$v) {_vm.searchStudentAssigned=$$v},expression:"searchStudentAssigned"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.studentHeaders,"items":_vm.studentsAssigned,"fixed-header":"","height":"50vh","calculate-widths":true,"disable-pagination":true,"options":_vm.options,"search":_vm.searchStudentAssigned,"loading":_vm.loadingAssignedStudents},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"add-student-btn",attrs:{"color":"primary","dark":""},on:{"click":_vm.addStudent}},[_vm._v(" Add Student ")]),_c('v-btn',{attrs:{"color":"primary","text":"","outlined":""},on:{"click":_vm.closeEdit}},[_vm._v(" Close ")])],1),_c('v-overlay',{staticClass:"pa-5",attrs:{"absolute":"","value":_vm.deleteOverlay,"color":"grey lighten-5","opacity":"0.9"}},[_c('v-progress-circular',{attrs:{"transition":"fade-transition","indeterminate":"","size":"64","color":"blue"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogStudent),callback:function ($$v) {_vm.dialogStudent=$$v},expression:"dialogStudent"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.studentsSearch,"item-text":"name","item-value":"id","label":"Student","hide-details":"","autofocus":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name + ' - ' + item.form_class)+" ")])],1)]}}]),model:{value:(_vm.studentSelected),callback:function ($$v) {_vm.studentSelected=$$v},expression:"studentSelected"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.teachers,"item-text":"name","item-value":"id","label":"Teacher"},on:{"change":_vm.setStudent},model:{value:(_vm.teacherSelected),callback:function ($$v) {_vm.teacherSelected=$$v},expression:"teacherSelected"}})],1)],1)],1)],1),_c('v-overlay',{staticClass:"pa-5",attrs:{"absolute":"","value":_vm.overlay,"color":"grey lighten-5","opacity":"0.9"}},[_c('v-progress-circular',{attrs:{"transition":"fade-transition","indeterminate":"","size":"64","color":"blue"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }