<template>
    <v-container>
        <v-row>
            <template v-for="(item, i) in menuItems">
                <v-col
                    :key="i"
                    class="flex-grow-0" 
                    v-if="item.display"               
                >
                    <v-hover >
                        <template v-slot:default=" { hover }">
                            <v-card
                                width="220"
                                height="150"
                                @click="display(item.option)"
                                elevation="4"
                                class="ma-4"
                            >
                                <v-card-title
                                    class="d-flex justify-center pb-0"
                                >
                                    <v-img
                                        :src="item.src"
                                        max-width="50"
                                        height="50"
                                        contain
                                        class="d-flex"
                                    ></v-img>
                                </v-card-title>
                                <v-card-title 
                                    class="subtitle-1 d-flex justify-center"
                                >
                                    {{ item.title }}
                                </v-card-title>
                                <v-card-subtitle
                                    class="caption d-flex justify-center"
                                >
                                    {{ item.subtitle }}
                                </v-card-subtitle>
                                <v-fade-transition>
                                    <v-overlay
                                        v-if="hover"
                                        absolute
                                        color="primary"
                                    ></v-overlay>
                                </v-fade-transition>
                            </v-card>
                        </template>
                    </v-hover>
                </v-col> 
            </template>    
        </v-row>
    </v-container>    
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: 'MainMenu',
    created(){
        this.initialize();
    },
    data: () => ({
        menuItems: [
            {
                icon: 'mdi-keyboard',
                title: 'Enter Marks ',
                subtitle: '(Subject Teachers)',
                src: require("../assets/icons/enter-marks.png"),
                option: 'markSheet',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Edit/View Term Details ',
                subtitle: '(Form Teachers)',
                src: require('../assets/icons/term-reports.png'),
                option: 'termDetails',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Print/View Reports',
                subtitle: 'Print Report Cards, Mark Sheets, etc.',
                src: require('../assets/icons/print-view-reports.png'),
                option: 'reports',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Employees',
                subtitle: 'Add / Edit Teachers, Timetables',
                src: require('../assets/icons/teacher.png'),
                option: 'employees',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Subjects',
                subtitle: 'Form 4-6 Student Subjects ',
                src: require('../assets/icons/subject-assignment.png'),
                option: 'subjects',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Students',
                subtitle: 'View/Edit Current Student Records',
                src: require('../assets/icons/edit-view-students.png'),
                option: 'students',
                display: false
            },           
            {
                icon: 'mdi-pencil',
                title: 'Reset Password',
                subtitle: 'Reset Employee Passwords',
                src: require('../assets/icons/reset-password.png'),
                option: 'resetPassword',
                display: false
            },   
                   
            {
                icon: 'mdi-pencil',
                title: 'Settings',
                subtitle: '',
                src: require('../assets/icons/settings.png'),
                option: 'settings',
                display: false
            }, 
           
        ],
        menu: '',  
    }),

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            userPermissions: 'auth/getPermissions',
            menuOptions: 'mainMenu/getMenuOptions',
        }),
       
    },
    
    methods: {
        ...mapMutations({
            setWelcomeMessage: 'mainMenu/setWelcomeMessage',                      
        }),               

        initialize(){
            console.log('initializing menu items...');
            let userPermissions = JSON.parse(sessionStorage.getItem('permissions'));
            
            let user = JSON.parse(this.user);
            
            if(user.employee_id == 'Admin'){
                this.menuItems.forEach(item => {
                    item.display = true;
                })
                return;
            }
            // console.log(this.userPermissions);
            userPermissions.forEach(permission => {
                if(permission.permission_id < 9 && this.menuItems[permission.permission_id - 1]){
                    this.menuItems[permission.permission_id - 1].display = true;
                }
                else if(permission.permission_id == 9){
                    // this.setEditTermMark(true);
                    // console.log("edit term mark permission");
                }                
            })
        },

        display(menu){
            this.setWelcomeMessage(false);
            Object.keys(this.menuOptions).forEach(key => {
                if(key == menu) this.menuOptions[key] = true;
                else this.menuOptions[key] = false;
            })
        },
    }
}
</script>