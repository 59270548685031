<template>
    <tr>
        <td>{{ lessonRecord.subject_id }}</td>
        <td> 
            <v-autocomplete
                v-model="lessonRecord.new_subject_id"
                :items="subjects"
                item-text="title"
                item-value="id"                
                outlined
                dense
                hide-details
                @blur="updateLesson"
            ></v-autocomplete>
        </td>
        <td>
            <v-autocomplete
                v-model="selectedClasses"
                :items="formClasses"
                outlined
                dense
                hide-details
                multiple
                chips
                deletable-chips
                small-chips
                @blur="updateLesson"
            ></v-autocomplete>
        </td>
        <td> 
            <v-icon
                small
                @click="deleteSelectedLesson"
                tabindex="-1"
            >
                mdi-delete
            </v-icon>
        </td>
        <td>            
            <v-progress-circular
                indeterminate
                size="16"
                width="3"
                v-if="saving"
            ></v-progress-circular>
            <v-icon
                small
                color="green" 
                v-else-if="saved"
            >
                mdi-check-all
            </v-icon>
            <v-icon
                small
                color="red"
                v-else-if="saveError"
            >
                mdi-alert-circle
            </v-icon>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        lesson: Object,
        index: Number,        
    },
    created () {
        //console.log('lesson created');
        this.initialize();
    },
    data: () => ({
        subjects: [],
        formClasses: [],
        lessonRecord: {},
        saving: false,
        saved: false,
        saveError: false,
        selectedClasses: [],       
             
    }),
    computed: {
        ...mapGetters({
            academicYearId : 'app/getAcademicYearId',
            teacherLessons :  'timetable/getTeacherLessons',
            lessonSubjects: 'timetable/getSubjects',
            lessonClasses: 'timetable/getFormClasses',
            editedEmployee: 'timetable/getEditedEmployee',            
        }),
    },
    watch: {
        //
    },
    methods: {
        async initialize(){
            this.subjects = [...this.lessonSubjects];
            this.formClasses = [...this.lessonClasses];            
            this.lessonRecord = {...this.lesson};
            this.selectedClasses = this.lesson?.form_classes;  
            this.lessonRecord.new_subject_id = this.lesson.subject_id; 
            this.saved = this.lesson.saved; 
        },
        ...mapMutations({
            setPostLesson: 'timetable/setPostLesson',
            setDeleteLesson: 'timetable/setDeleteLesson',
            setTeacherLessons: 'timetable/setTeacherLessons',            
        }),
        ...mapActions({
            postLesson: 'timetable/postLesson',
            deleteLesson: 'timetable/deleteLesson',
            displayModal: 'app/displayModal',
            getTeacherLessons: 'timetable/getTeacherLessons',            
        }),

        async updateLesson(){
            if(!this.selectedClasses || this.selectedClasses.length == 0 || !this.lessonRecord.new_subject_id){
                //fields incomplete
                return;
            }

            this.saved = false;
            this.saveError = false; 
            this.saving = true;
            this.lessonRecord.formClasses = this.selectedClasses;
            this.setPostLesson(this.lessonRecord);

            try {
                await this.postLesson();
                this.saving = false;
                this.saveError = false;
                this.saved = true;
                let teacherLessonsCopy = [...this.teacherLessons];
                if(teacherLessonsCopy.length == 0 ) return;
                teacherLessonsCopy[this.index].subject_id = this.lessonRecord.new_subject_id;
                teacherLessonsCopy[this.index].form_classes = this.selectedClasses;
                teacherLessonsCopy[this.index].saved = true;
                this.setTeacherLessons([]);
                this.$nextTick(() => {
                    this.setTeacherLessons(teacherLessonsCopy)
                });
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error)
                let payload = {
                    response: error.response,
                    icon: 'error',                    
                }
                this.displayModal(payload);
                this.saving = false;
                this.saved = false;
                this.saveError = true;
            }                 
        },
        
        async deleteSelectedLesson(){            
            if(this.lesson.subject_id && this.lesson.form_classes.length != 0){
                this.saved = false;
                this.saveError = false;
                this.saving = true;           
                
                this.setDeleteLesson(this.lesson);                              
                
                try {
                    const { data } = await this.deleteLesson();
                    const lessonsDeleted = data.length;
                    console.log(`${lessonsDeleted} Lessons Deleted`)
                    this.saving = false;
                    this.saved = false;
                    this.saveError = false;

                    let teacherLessonsReduced = this.teacherLessons.filter( (lesson, index) => {                        
                        return index !== this.index;
                    })

                    // response = await this.getTeacherLessons(this.employee_id);
                    // console.log(response);                
                    // let records = response.data;
                    
                    // //let teacherLessonsCopy = [...this.teacherLessons];
                    this.setTeacherLessons([]);
                    this.$nextTick(() => {
                        this.setTeacherLessons(teacherLessonsReduced);
                    })                    

                } catch (error) {                   
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.saving = false;
                    this.saved = false;
                    this.saveError = true;
                }
            }
            else{
               //remove blank lesson
               let teacherLessonsReduced = this.teacherLessons.filter( (lesson, index) => {                    
                    return index !== this.index;
                })

                this.setTeacherLessons([]);
                this.$nextTick(() => {
                    this.setTeacherLessons(teacherLessonsReduced);
                })
            }
            
        }
    }
}
</script>